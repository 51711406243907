import './App.css';

function App() {
  return (
    <div className="container">
      <main className="main">
        <div className="content">
          {/* Logo */}
          <div className="logo">
            <span>Devestry</span>
          </div>
          
          {/* Hero Section */}
          <h1 className="title">
            Under Construction
          </h1>
          
          <p className="description">
            Our no-code fullstack platform is taking shape. We're building something extraordinary for developers and creators alike.
          </p>
          
          {/* CEO Attribution */}
          <div className="ceo-profile">
            <div className="ceo-avatar">
              <span>FM</span>
            </div>
            <div className="ceo-info">
              <p className="ceo-name">Filip Michałkiewicz</p>
              <p className="ceo-title">CEO of Devestry</p>
            </div>
          </div>
          
          {/* Launch Date */}
          <div className="launch-date">
            <span>Coming to revolutionize development in 2025/2026</span>
          </div>
          
          {/* Vercel-like Grid */}
          <div className="feature-grid">
            {/* Grid items with gradient borders */}
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">Intuitive Visual Editor</h3>
              <p className="feature-description">Drag-and-drop components to build your application's interface with pixel-perfect precision.</p>
            </div>
            
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">Database Abstraction</h3>
              <p className="feature-description">Define your data model visually and let our platform handle the complex database operations.</p>
            </div>
            
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">API Generation</h3>
              <p className="feature-description">Automatically generate RESTful APIs based on your data models without writing backend code.</p>
            </div>
            
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">Global Deployment</h3>
              <p className="feature-description">Deploy to our global edge network for optimal performance and reliability worldwide.</p>
            </div>
            
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">Real-time Collaboration</h3>
              <p className="feature-description">Build together with your team in real-time, seeing changes instantly as they happen.</p>
            </div>
            
            <div className="feature-card">
              <div className="feature-gradient"></div>
              <h3 className="feature-title">Zero Infrastructure</h3>
              <p className="feature-description">Focus on creating, not configuring. We handle all server and infrastructure management.</p>
            </div>
          </div>
          
          {/* Background Gradient Element */}
          <div className="background-gradient"></div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-links">
            <a href="mailto:hello@devestry.com" className="footer-link">
              hello@devestry.com
            </a>
            <a href="https://buymeacoffee.com/locz3k" className="footer-link">
              Buy me a coffee ☕
            </a>
          </div>
          <div className="copyright">
            © {new Date().getFullYear()} Devestry. All rights reserved.
          </div>
        </div>
      </footer>
    </div>);
}

export default App;
